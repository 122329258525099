<template>
  <div
    ref="echartContainer"
    style="width: inherit; height: inherit"
  >
    <loading
      v-show="loading"
      :message="'Loading data'"
    />
    <div
      v-show="!loading"
      ref="echart"
      v-resize="onResize"
      style="width: inherit; height: inherit;"
    />
  </div>
</template>
<script>
import * as echarts from 'echarts';
import 'echarts-wordcloud';

import ApiService from '../../../services/api.service';

import { createSeriesOption } from './lib/transformer';
import wordcloudData from "../../../data/topics.wordcloud.json";

import { createWordcloudQueryParams } from '../../../lib/query-creator';

const Loading = () => import('@/components/loading.vue');

export default {
  components: {
    Loading,
  },
  props: ['dataset', 'label'],
  data() {
    return {
      loading: false,
      chart: null,
      width: null,
      height: null,
    };
  },
  computed: {},
  mounted() {
    const that = this;
    this.init();
    that.loadData();
    // that.loading = true;
    // const timeout = setTimeout(() => {
    //   that.loadData();
    //   clearTimeout(timeout);
    // }, 200);
  },
  methods: {
    async loadData() {
      if (this.dataset && this.dataset.table_name) {
        try {
          this.loading = true;
          const appConfig = this.$store.state.appConfig;

          const params = {
            xColumn: 'Date',
            yColumn: 'value',
            valueColumn: 'value',
            labelColumn: 'Location',
          };
          // console.log('this.dataset', this.dataset);
          if (this.$refs.echartContainer.clientHeight) {
            this.height = this.$refs.echartContainer.clientHeight;
          }
          if (this.$refs.echartContainer.clientWidth) {
            this.width = this.$refs.echartContainer.clientWidth;
          }
          // const p = createWordcloudQueryParams(this.dataset, this.label, this.height, this.width);
          // console.log('p', p);
          // const response = await ApiService.post('/raw_sql/', { q: p.sql });
          this.loading = false;
          // const data = response.data;
          const data = [...wordcloudData];
          const option = createSeriesOption(data, params);
          console.log('option', option);
          this.draw(option);
        } catch (error) {
          //
        }
        this.loading = false;
      }
    },
    init() {
      this.chart = echarts.init(this.$refs.echart);
    },
    draw(option) {
      console.log('option', option);
      this.chart.setOption(option);
    },
    rendered() {},
    onResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};
</script>
