export const createSeriesOption = (data, settings, height, width) => {
  const {
    xColumn, yColumn, valueColumn, labelColumn,
  } = settings;
  const d = data.filter((item) => item.name.length > 1).map((item) => {
    const result = {
      name: item.name,
      value: item.count,
    };
    return result;
  });
  const option = {
    tooltip: {},
    series: [{
      type: 'wordCloud',
      gridSize: 8,
      sizeRange: [12, 60],
      rotationRange: [-90, 90],
      shape: 'circle',
      keepAspect: false,
      left: 'center',
      top: 'center',
      width: '100%',
      height: '100%',
      right: null,
      bottom: null,
      drawOutOfBound: false,
      layoutAnimation: true,
      textStyle: {
        color() {
          return `rgb(${[
            Math.round(Math.random() * 160),
            Math.round(Math.random() * 160),
            Math.round(Math.random() * 160),
          ].join(',')})`;
        },
      },
      emphasis: {
        textStyle: {
          shadowBlur: 10,
          shadowColor: '#333',
        },
      },
      data: d,
    }],
  };
  return option;
};